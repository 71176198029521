import { DialogCloseResult, DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { NavigationInstruction, PipelineResult, Router, RouterEvent } from 'aurelia-router';
import { MenuItem } from 'components/models/MenuItem';
import { Add } from 'pages/contacts/add/add';
  
  @autoinject()
  export class HeaderTitleContact {
    message: string;
    @bindable private title: string;
    @bindable private subtitle: string;
    @bindable private menuItems: Array<MenuItem> = [];
    @bindable private isEditing: boolean = false;
    private navEvent;
    private currentInstruction;
    private currentRouteParam;
    private isDropDown: boolean = false;
    
    constructor(private router: Router, private ea: EventAggregator, private dialogService: DialogService) { //Initialize the page
      this.navEvent = this.ea.subscribe(RouterEvent.Success, (event: { instruction: NavigationInstruction; result: PipelineResult }) => {
        // console.log("current route: " + JSON.stringify(event.instruction.params));
        // console.log(event.result)
        this.currentInstruction = event.instruction 
        this.currentRouteParam = this.currentInstruction.params;
      });
    }

    addContact() {
      this.dialogService.open({ viewModel: Add, lock: false })
      // .whenClosed(result => {
      // console.log(result)
      //   if(result)
      //     this.router.navigateToRoute("contacts", { page: "sent" })
      // })
    }

    addBeneficiary() {
      this.router.navigateToRoute("create_recipients")
    }

    active() {} //Get url parameters

    // bind () {
    //   console.log("Menus bound: " + this.menuItems)
    //   console.log("Investigating router params");
    //   console.log(this.currentInstruction.params.page)
    // } //connects the variable bindable with the html view

    // isRouteActive(urlParam) {
    //   let isActive: boolean = false;
    //   // console.log(JSON.stringify(this.currentInstruction.params))
    //   console.log(this.currentRouteParam);
    //   console.log(urlParam)

    //   isActive = this.currentRouteParam === urlParam;
    //   return isActive;
    // }

    deactivate() {
      this.navEvent.dispose();
    }
  }
  